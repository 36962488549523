<template>
  <CsModal ref="csDialogModal" :close-on-backdrop-click="false">
    <template #aria-live>
      <slot name="aria-live" />
    </template>
    <div class="px-4 py-6 mini:p-6">
      <div>
        <span class="h4">
          {{ title }}
        </span>
        {{ text }}
      </div>

      <slot />
      <menu class="p-0 m-0 flex flex-wrap justify-end gap-x-4 gap-y-2">
        <Button
          v-if="onCancel"
          ref="cancel"
          color="gray"
          size="medium"
          :disabled="cancelDisabled"
          @click="$emit('cancel', $event)"
        >
          {{ cancelLabel }}
        </Button>
        <Button
          v-if="onConfirm"
          ref="confirm"
          value="default"
          size="medium"
          :disabled="confirmDisabled"
          @click="$emit('confirm', $event)"
        >
          {{ confirmLabel }}
        </Button>
      </menu>
    </div>
  </CsModal>
</template>

<script setup lang="ts">
  import CsModal from "./CsModal.vue"
  const csDialogModal = ref<InstanceType<typeof CsModal> | null>(null)

  defineEmits(["confirm", "cancel"])

  defineProps<{
    title?: string
    text?: string
    onConfirm?: () => any
    onCancel?: () => any
    confirmLabel?: string
    cancelLabel?: string
    confirmDisabled?: boolean
    cancelDisabled?: boolean
  }>()

  defineExpose({
    /**
     * Open the dialog.
     */
    openModal: () => {
      csDialogModal.value?.openModal()
    },
    /**
     * Close the dialog.
     */
    close: () => {
      csDialogModal.value?.close()
    },
  })
</script>

<style scoped></style>
